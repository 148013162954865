import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';


export default class InitGsap {
	static init() {
		this.anmifadeup();
	}


	static anmifadeup() {
		gsap.registerPlugin( ScrollTrigger );

		ScrollTrigger.defaults( {
			markers: false,
		} );

		ScrollTrigger.batch( '.anmi-fadeup', {
			onEnter: elements => {
				gsap.from( elements, {
					autoAlpha: 0,
					y: 60,
					stagger: 0.005,
				} );
			},
			once: true,
		} );

		ScrollTrigger.batch( '.anmi-fadedown', {
			onEnter: elements => {
				gsap.from( elements, {
					autoAlpha: 0,
					y: -60,
					stagger: 0.005,
				} );
			},
			once: true,
		} );

		ScrollTrigger.batch( '.anmi-fadefromleft', {
			onEnter: elements => {
				gsap.from( elements, {
					autoAlpha: 0,
					x: -60,
					stagger: 0.005,
				} );
			},
			once: true,
		} );

		ScrollTrigger.batch( '.anmi-fadefromright', {
			onEnter: elements => {
				gsap.from( elements, {
					autoAlpha: 0,
					x: 60,
					stagger: 0.005,
				} );
			},
			once: true,
		} );

		ScrollTrigger.batch( '.anmi-bounce', {
			onEnter: elements => {
				gsap.from( elements, {
					duration: 1, // Adjust duration as needed (in seconds)
					scale: 1.2,  // Scale up for the pop effect
					ease: "bounce.out", // Bouncy easing for the pop
					opacity: 1,  // Ensure image has opacity (if needed)
				} );
			},
			once: true,
		} );

		ScrollTrigger.batch(".bounce-all div", {
		  onEnter: (elements) => {
		    gsap.from(elements, {
		      autoAlpha: 0, // Start invisible
		      y: 60, // Translate 60px up initially
		      duration: 1, // Adjust duration as needed (in seconds)
		      ease: "power3.inOut", // Customize easing if desired
		      stagger: 0.25, // Adjust stagger delay between icons (seconds)
		    });
		  },
		  once: true, // Animate only once when entering viewport
		});
	}
}


const nav = document.querySelector('nav');
const scrollTrigger = 150; // Adjust this value to define scroll threshold
const fixedTrigger = 475; // Adjust this value to define scroll threshold

window.addEventListener('scroll', function() {
	console.log(window.scrollY);
  if (window.scrollY > scrollTrigger) {
  	console.log(nav.classList);
    nav.classList.add('scrolled');
  } else {
    nav.classList.remove('scrolled');
  }
  if (window.scrollY > fixedTrigger) {
  	console.log(nav.classList);
    nav.classList.add('fixed');
  } else {
    nav.classList.remove('fixed');
  }
});
